const getEnv = (key, defaultValue = undefined) => {
  if (isDefined(window.ENV?.[key])) return window.ENV[key];

  const envVariablesInput = document.querySelector("input[type='hidden']#env");
  if (envVariablesInput?.value) {
    const value = JSON.parse(envVariablesInput.value)?.[key];
    if (isDefined(value)) return value;
  }

  return defaultValue;
};

export default getEnv;

const isDefined = (value) => value !== undefined && value !== null;

import I18n from "i18n-js";
import set from "lodash/set";
import getEnv from "utils/getEnv";

const defaultLocale = "en";

export const SUPPORTED_LANGUAGES_FOR_KIOSK = [
  { label: "English", value: "en" },
  { label: "Español", value: "es" },
];

export const SUPPORTED_LANGUAGES_FOR_UNIVERSAL_AUTH_FORM = [
  { label: "English", value: "en" },
  {
    label: "Español (Spanish)",
    value: "es",
    note: {
      title: "NOTA: ",
      description:
        "Tenga en cuenta que el texto incluido en las siguientes páginas es una traducción del inglés, en " +
        "algunos casos realizada por medios automáticos, y podría no reflejar con exactitud el texto original.",
    },
  },
];

export const currentLocale = () => {
  const envLocale = getEnv("locale", defaultLocale);

  return window.locale || envLocale;
};

export const sessionLocale = () => getEnv("sessionLocale", null);

export const addTranslations = (data, scope) => {
  const globalScope = scope ? `javascript.${scope}` : "javascript";

  const translations = { en: {}, es: {} };
  set(translations.en, globalScope, data.en);
  set(translations.es, globalScope, data.es);

  I18n.translations = I18n.extend(I18n.translations || {}, translations);
};

const initTranslationsHelper = (scope, params = {}) => {
  I18n.defaultLocale = defaultLocale;
  I18n.locale = params.locale || currentLocale();

  const globalScope = scope ? `javascript.${scope}` : "javascript";
  const translate = (key, options = {}) => key && I18n.t(`${globalScope}.${key}`, options);

  return translate;
};

export default initTranslationsHelper;

/* eslint-disable */
require("whatwg-fetch");

const RETRY_TIMEOUT = 5000;
const INITIAL_RETRIES = 3;
const wait = () => new Promise((resolve) => { setTimeout(resolve, RETRY_TIMEOUT); });

const fetchRetry = (url, fetchOptions = {}, tries = INITIAL_RETRIES) => {
  const onError = (error) => {
    const triesRemaining = tries - 1;
    if (!(error instanceof TypeError) || !triesRemaining) {
      return window.Rollbar.warn(error.message, Promise.resolve);
    }
    return wait().then(() => fetchRetry(url, fetchOptions, triesRemaining));
  };
  return fetch(url, fetchOptions).catch(onError);
};

export default class Analytics {
  constructor(rootUrl) {
    this.rootUrl = rootUrl || "";
  }

  eventsPath() {
    return `${this.rootUrl}/analytics/events`;
  }

  sendEventData(data) {
    return fetchRetry(
      this.eventsPath(),
      {
        method: "POST",
        headers: { "Content-Type": "application/json", "X-Requested-With": "XMLHttpRequest" },
        body: JSON.stringify(data),
        ...(this.rootUrl ? { credentials: "include" } : {})
      },
    );
  }

  ready(callback) {
    callback && callback();
  }

  getUserId(properties) {
    return properties.userId ? properties.userId : `engagement-${properties["engagement_id"]}`;
  }

  page(eventName, properties) {
    const event_name = `Viewed ${eventName} Page`;
    properties["frontend"] = true;
    const attributes = {
      event: event_name,
      properties: properties,
      user_id: this.getUserId(properties),
    };
    return this.sendEventData({
      event: { method: "page", properties: attributes },
    });
  }

  track(eventName, properties) {
    properties["frontend"] = true;
    const attributes = {
      event: eventName,
      properties: properties,
      user_id: this.getUserId(properties),
    };
    return this.sendEventData({
      event: { method: "track", properties: attributes },
    });
  }
}

/* eslint-enable */

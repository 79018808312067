const EventEmitter = {
  events: {},
  dispatch(eventName, data) {
    (this.events[eventName] || []).forEach((callback) => callback(data));
  },
  subscribe(eventName, callback) {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }

    this.events[eventName].push(callback);
  },
};

export default EventEmitter;
